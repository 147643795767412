@import "@angular/cdk/overlay-prebuilt.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

